/*
 * Callback for OneTrust
 *
 * see https://community.cookiepro.com/s/article/UUID-29158b4e-22f6-0067-aa36-94f3b8cf3561
 */

let mapLoaded = false;
let activeGroups = [];

function initializeIssueIframes () {
    if (document.querySelector('.issuu-frame-control')) {
        if (activeGroups.includes('C0002')) {
            enableIssue();
        }
    }
}

function enableIssue () {
    const issuuWidget = document.querySelector('iframe[name="leaflet-display"]');
    if (issuuWidget) {
        const frameSrc = issuuWidget.getAttribute('data-src');
        if(frameSrc) {
            issuuWidget.setAttribute('src', frameSrc);
            issuuWidget.style.display = '';
        }
    }

    const issueWidgetHints = document.querySelectorAll('.issuu-frame-control .cookie-consent-infotext, .issuu-fallback-wrapper');
    issueWidgetHints.forEach(issueWidgetHint => {
        if (issueWidgetHint) {
            issueWidgetHint.style.display = 'none';
        }
    })
}

function initializeGoogleMap () {
    if (document.getElementById('map')) {
        if (activeGroups.includes('C0002')) {
            if(document.querySelector('.show-map')) {
                document.querySelector('.show-map').style.display = 'block';
            }
            document.querySelector('.maps-cookie-info.cookie-consent-infotext').style.display = 'none';
            if (!mapLoaded) {
                mapLoaded = true;
                const script = document.createElement('script');
                script.src = `https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}&callback=initMap`;
                script.defer = true;
                document.head.appendChild(script);
            }
        } else {
            if(document.querySelector('.show-map')) {
                document.querySelector('.show-map').style.display = 'none';
            }
            document.querySelector('.maps-cookie-info.cookie-consent-infotext').style.display = 'block';
        }
    }
}

function initializeYoutubeVideos() {
    if (activeGroups.includes('C0004')) {
        for (const video of document.querySelectorAll('.video-embed .optanon-category-C0004')) {
            video.style.display = 'block';
        }
        for (const cookieInfoBox of document.querySelectorAll('.video-embed .cookie-consent-infotext')) {
            cookieInfoBox.style.display = 'none';
        }
    }
}

// global so it can be called from debugging code (see constant plugin.tx_adeg.simulatedOneTrustGroups)
window.initWidgets = function () {
    activeGroups = window.OnetrustActiveGroups.split(',').filter(value => value.trim());

    console.debug('Active OneTrust groups: ' + activeGroups);

    initializeIssueIframes();
    initializeGoogleMap();
    initializeYoutubeVideos();
};

// will be called on page load and on every change of the consent settings
window.OptanonWrapper = function () {

    OneTrust.OnConsentChanged(function () {
        window.location.reload();
    });

    /*
     * Remove cookies after user opt-out (copied from the documentation)
     *
     * https://community.cookiepro.com/s/article/UUID-b2d72003-853b-e75b-e337-c6d26ea327eb
     */

    // added by plan2net for strict mode added by Webpack
    let otIniGrps, domainName, pathArray;

    //Get initial OnetrustActiveGroups ids
    if (typeof window.OptanonWrapperCount == 'undefined') {
        otGetInitialGrps();
    }

    //Delete cookies
    otDeleteCookie(otIniGrps);

    //Assign OnetrustActiveGroups to custom variable
    function otGetInitialGrps () {
        window.OptanonWrapperCount = '';
        otIniGrps = window.OnetrustActiveGroups;
    }

    function otDeleteCookie (iniOptGrpId) {
        var otDomainGrps = JSON.parse(JSON.stringify(Optanon.GetDomainData().Groups));
        var otDeletedGrpIds = otGetInactiveId(iniOptGrpId, window.OnetrustActiveGroups);
        if (otDeletedGrpIds.length != 0 && otDomainGrps.length != 0) {
            for (var i = 0; i < otDomainGrps.length; i++) {
                //Check if CustomGroupId matches
                if (otDomainGrps[i]['CustomGroupId'] != '' && otDeletedGrpIds.includes(otDomainGrps[i]['CustomGroupId'])) {
                    for (var j = 0; j < otDomainGrps[i]['Cookies'].length; j++) {
                        //Delete cookie
                        eraseCookie(otDomainGrps[i]['Cookies'][j]['Name']);
                    }
                }

                //Check if Hostid matches
                if (otDomainGrps[i]['Hosts'].length != 0) {
                    for (var j = 0; j < otDomainGrps[i]['Hosts'].length; j++) {
                        //Check if HostId presents in the deleted list and cookie array is not blank
                        if (otDeletedGrpIds.includes(otDomainGrps[i]['Hosts'][j]['HostId']) && otDomainGrps[i]['Hosts'][j]['Cookies'].length != 0) {
                            for (var k = 0; k < otDomainGrps[i]['Hosts'][j]['Cookies'].length; k++) {
                                //Delete cookie
                                eraseCookie(otDomainGrps[i]['Hosts'][j]['Cookies'][k]['Name']);
                            }
                        }
                    }
                }

            }
        }
        otGetInitialGrps(); //Reassign new group ids
    }

    //Get inactive ids
    function otGetInactiveId (customIniId, otActiveGrp) {
        //Initial OnetrustActiveGroups
        customIniId = customIniId.split(',');
        customIniId = customIniId.filter(Boolean);

        //After action OnetrustActiveGroups
        otActiveGrp = otActiveGrp.split(',');
        otActiveGrp = otActiveGrp.filter(Boolean);

        var result = [];
        for (var i = 0; i < customIniId.length; i++) {
            if (otActiveGrp.indexOf(customIniId[i]) <= -1) {
                result.push(customIniId[i]);
            }
        }
        return result;
    }

    //Delete cookie
    function eraseCookie (name) {
        //Delete root path cookies
        domainName = window.location.hostname;
        document.cookie = name + '=; Max-Age=-99999999; Path=/;Domain=' + domainName;
        document.cookie = name + '=; Max-Age=-99999999; Path=/;';

        //Delete LSO incase LSO being used, cna be commented out.
        localStorage.removeItem(name);

        //Check for the current path of the page
        pathArray = window.location.pathname.split('/');
        //Loop through path hierarchy and delete potential cookies at each path.
        for (var i = 0; i < pathArray.length; i++) {
            if (pathArray[i]) {
                //Build the path string from the Path Array e.g /site/login
                var currentPath = pathArray.slice(0, i + 1).join('/');
                document.cookie = name + '=; Max-Age=-99999999; Path=' + currentPath + ';Domain=' + domainName;
                document.cookie = name + '=; Max-Age=-99999999; Path=' + currentPath + ';';
                //Maybe path has a trailing slash!
                document.cookie = name + '=; Max-Age=-99999999; Path=' + currentPath + '/;Domain=' + domainName;
                document.cookie = name + '=; Max-Age=-99999999; Path=' + currentPath + '/;';

            }
        }
    }

    /*
     * Additional code for custom UI elements added by plan2net
     */
    initWidgets();
};
